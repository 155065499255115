import styled from "styled-components";
import backGroundImg from "../../../assets/images/drop-down.png";

export const StylesCardList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const StyledCard = styled.div`
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 23%;  
    margin: 10px;  

    &:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }
`;

export const StyledCardContainer = styled.div`
    padding: 20px 0px 20px 0px;

    & a {
        text-decoration: none;
        color: #000;
    }
`;

export const StyledContainer = styled.div``;

export const StyledPageTitle = styled.div`
  background: #ce1127;
  color: #fff;
  border-radius: 5px;
  padding: 10px 25px;
  margin-bottom: 20px;
  display: flex;
  gap: 8px;
  align-items: baseline;

  & h1 {
    font-size: 20px;
    color: #ffffff;
    font-weight: 600;
  }
`;

export const SearchField = styled.div`
 float: right;
 position:relative;
 z-index:10;
`;

export const StyledDataTable = styled.div.attrs({ className: "styledTable" })`
  // & input {
  //   width: 25px;
  //   height: 25px;
  //   border: 1px solid #848484;
  //   border-radius: 5px;
  //   display: inline-block;
  //   vertical-align: middle;
  //   margin-right: 15px;
  //   position: relative;
  //   top: -1px;
  // }
  input:disabled {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
  }
  .uspassport-custom-tbl {
    overflow-x: unset;
    overflow-y: unset;
  }
  .rdt_Pagination {
    border-top: none;
  }
  .jrvHCp {
    display: flex;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    background-color: #ffffff;
    min-height: 80px !important;
  }
  .dptOXT {
    display: flex;
    -webkit-box-align: stretch;
    align-items: center;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    background-color: rgb(255, 255, 255);
    min-height: 80px;
  }

  .kxAIHs { 
    align-items: center !important;
  }
  .rdt_TableCol {
    font-weight: bold;
  }

  & div:first-child {
    white-space: pre-line;
    text-overflow: ellipsis;
    min-width: fit-content;
    max-height: fit-content;
  }

  .emmFZe div:first-child {
    white-space: pre-line;
    text-overflow: ellipsis;
    max-height: fit-content;
  }
  .table-block table.dataTable td,
  .table-block table.dataTable th {
    font-size: 0.875em;
    padding: 8px 10px;
  }

  .dMpKVF {
    display: flex;
    -webkit-box-align: stretch;
    align-items: center;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    background-color: rgb(255, 255, 255);
    min-height: 80px !important;
  }

  .fkWryv {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    background-color: #ffffff;
    min-height: 80px;
  }

  .caAlCG {
    flex-grow: 1;
    flex-shrink: 0;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
    font-size: 0.875em;
    padding: 10px 30px;
    /* min-width: 100px; */
  }

  .table-block table td {
    border-top: 1px solid #e8f5ff;
    font-size: 16px;
    padding: 10px;
    vertical-align: top;
  }
  .table-block table tr:nth-child(even) {
    background: #ccc;
  }

  .table-block table tr:nth-child(odd) {
    background: #fff;
  }

  & td {
    border-top: 1px solid #e8f5ff;
    font-size: 16px;
    padding: 5px;
    vertical-align: top;
  }
  & tr {
    padding: 10rem;
  }

  @media (min-width: 576px) .col-sm-6 {
    flex: 0 0 auto;
    /* width: 50%; */
  }
`;

export const StyledOrderBlock = styled.div`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);
  border-radius: 5px;
  padding: 15px 25px;
  margin-bottom: 20px;

  & h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .table-block table {
    width: 100%;
  }

  .table-block table th {
    font-weight: 600;
    font-size: 18px;
    padding: 10px;
  }
  .table-block table td {
    border-top: 1px solid #e8f5ff;
    font-size: 16px;
    padding: 10px;
    vertical-align: top;
  }
  .table-block table tr:nth-child(even) td {
    background: #f9fcff;
  }

  .select-box {
    border: 1px solid #e1e1e1;
    height: 40px;
    box-shadow: none;
    max-width: 260px;
    background-image: url(${backGroundImg});
    background-repeat: no-repeat;
    background-position: 93%;
  }

  .form-control {
    display: block;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .dMpKVF {
    display: flex;
    -webkit-box-align: stretch;
    align-items: center !important;
    align-content: stretch;
    width: 100%;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.87);
    background-color: rgb(255, 255, 255);
    min-height: 80px !important;
  }

  & div:first-child {
    white-space: pre-line;
    text-overflow: ellipsis;
    // min-width: fit-content;
  }
`;

export const StyledBottomBlock = styled.div`
  margin-top: 35px;
  .blue-btn {
    margin: 0px 10px 10px 0px;
  }
  .blue-btn {
    background-color: #ce1127;
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 30px;
    display: inline-block;
  }
  .blue-btn img {
    margin-left: 10px;
    vertical-align: middle;
    display: inline-block;
  }
  .blue-btn:hover,
  .blue-btn:focus {
    background-color: #0042b3;
    color: #fff;
    text-decoration: none;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  border-width: 2px;
  font-weight: 400;
  font-size: 0.93em;
  line-height: 1.35em;
  margin: 20px 1px;
  border: none;
  border-radius: 0.1875rem;
  cursor: pointer;
  background-color: #fff;
  color: #fff;
  gap: 1rem;

  & button.btn {
    background-color: #ce1127;
    border-color: ##ce1127;
  }
`;


export const ButtonWrapperDelete = styled.div`
  width: 71%;
  margin-bottom: 30px;
  .blue-btn {
    margin-top: 5px;
    margin-right: 5px;
  }

  .blue-btn {
    background-color: #18f;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 30px;
    display: inline-block;
    text-decoration: none;
    border-radius: 5px;
    border: none;

    &:focus,
    &:hover {
      background-color: #0042b3;
      color: #fff;
      -webkit-text-decoration: none;
      text-decoration: none;
    }

    & img {
      margin-left: 10px;
      vertical-align: middle;
      display: inline-block;
    }

    @media only screen and (max-width: 1120px) {
      font-size: 16px;
      padding: 12px 10px;
    }

    @media only screen and (max-width: 991px) {
      font-size: 15px;
      padding: 12px 10px;
    }

    @media only screen and (max-width: 767px) {
      font-size: 12px;
      padding: 10px 15px;
    }
  }
`;

