import React, { useEffect, useState } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock, StyledCard, StyledCardContainer, StylesCardList, ButtonWrapper, SearchField, StyledDataTable, ButtonWrapperDelete } from "./style";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersList, deleteOrdersData, getAllFolderCount, handleChange } from "../../../features/order/orderSlice";
import { toast } from "react-toastify";
import { encryptVal } from "../../../utils/utility";
import MyModal from "../../../components/Modal";
import moment from 'moment';
import DataTable from "react-data-table-component";
import { DebounceInput } from "react-debounce-input";
import { jwtDecode } from "jwt-decode";
import rightArrow from "../../../assets/images/right-arrow.png";


const FutureOrders = () => {
    const isLoading = useSelector((state) => state?.order?.loading);
    const SelectedData = useSelector((state) => state?.order?.selectedData);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const futureOrdersList = useSelector((state) => state?.order?.ordersList);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [show, setShow] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const handleClose = () => setShow(false);
    const [pageNum, setPageNum] = useState(1);
    const [orderName, setOrderName] = useState("futureOrder");
    let [totalRows, setTotalRows] = useState(0);
    const [rowLimit, setRowLimit] = useState(10);

    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [futureOrdersData, setFutureOrdersData] = useState([]);
    const [dataTableData, setDataTableData] = useState({
        renderDataTable: false,
        data: [],
        month: "",
    });
    const token = localStorage.getItem("user");
    let userType;
    if (token !== null) {
        const decodedToken = jwtDecode(token);
        userType = decodedToken?.user?.type;
    }

    useEffect(() => {
        setFutureOrdersData(dataTableData.data);
    }, [dataTableData.data])

    useEffect(() => {
        setCurrentPage(pageNum);
        orderName?.includes("futureOrder")
            ? setTotalRows(dataTableData.data?.length)
            : setTotalRows(0);
    }, [pageNum, orderName]);

    const handleShow = (orderId) => {
        setShow(true);
        setSelectedData([orderId]);
    };

    useEffect(() => {
        if (SelectedData && SelectedData?.length !== 0) {
            setSelectedData(SelectedData);
        }
    }, [SelectedData]);

    let futureMonthDataArray = [];


    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const [futureOrderData, setFutureOrderData] = useState([]);

    const deleteOrder = (orderID) => {
        let data = {
            orderId: orderID,
            permanentDeletedFlag: false,
            updatingStatus: "Deleted",
        };
        dispatch(deleteOrdersData(data)).then((res) => {
            handleClose();
            if (res.payload) {
                toast.success(`Order has been deleted successfully`, {
                    className: "toast-message",
                });
                dispatch(
                    getOrdersList({
                        orderName: "futureOrder",
                        page: page,
                        perPage: limit,
                    })
                );
                dispatch(getAllFolderCount());
            } else {
                toast.error(`${res.message}`, {
                    className: "toast-message",
                });
            }
        }).catch((err) => {
            if (err.status === 0) {
                toast.success(`${err.message}`, {
                    className: "toast-message",
                });
            }
        });
    };



    const columns = [
        {
            name: "Order ID",
            selector: (row) => row.order_id,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.first_name + " " + row.middle_name + " " + row.last_name,
            sortable: true,
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: "Telephone",
            selector: (row) => row.phone_number,
            sortable: true,
        },
        {
            name: "Date & Time",
            selector: (row) => row.created_at
                ? moment(new Date(row.created_at)).format("DD-MM-YYYY hh:mm:ss")
                : "",
            sortable: true,
        },
        {
            name: "Processing Date ",
            selector: (row) => row.processing_date,
            sortable: true,
        },
        {
            name: "Process Month",
            selector: (row) => row.process_month,
            sortable: true,
        },

        {
            name: "Status",
            selector: (row) => row.process_status,
            sortable: true,
        },

        {
            name: "Action",
            selector: (row) =>
                futureOrdersList?.length ? (
                    <span style={{ display: 'flex' }}>
                        <Link
                            to={`/admin/view-order/${encryptVal(row.order_id)}`}
                            style={{
                                border: "1px solid #18f",
                                borderRadius: "5px",
                                padding: " 0 5px",
                                minWidth: "80px",
                                display: "inline-block",
                                textAlign: "center",
                                height: "40px",
                                lineHeight: "40px",
                                color: "#111",
                                margin: "5px",
                                textDecoration: "none",
                            }}
                        >
                            View
                        </Link>{" "}

                        {userType === "Manager" || userType === "Admin" && (
                            <Link
                                to="#"
                                onClick={() => handleShow({ id: row?.order_id, status: row?.status })}
                                style={{
                                    textDecoration: "none",
                                    border: "1px solid #e92a11",
                                    borderRadius: "5px",
                                    padding: " 0 5px",
                                    minWidth: "80px",
                                    display: "inline-block",
                                    textAlign: "center",
                                    height: "40px",
                                    lineHeight: "40px",
                                    color: " #e92a11",
                                    margin: "5px",
                                }}
                            >
                                Delete
                            </Link>
                        )}
                    </span>
                ) : (
                    ""
                ),
        },
    ];

    useEffect(() => {
        dispatch(getOrdersList({
            orderName: "futureOrder",
            page: page,
            perPage: limit,
        })).unwrap().then((res) => {
            if (res.status === 401) {
                toast.error(`${res?.message}`, { className: "toast-message" });
                localStorage.removeItem("user");
                navigate("/");
            }
        });
        document.title = "Phi- ETD";
    }, [dispatch, navigate, page, limit]);

    useEffect(() => {
        const tableData = futureOrdersList || [];
        for (let data of tableData) {
            if (typeof futureMonthDataArray[data.process_month] !== "undefined") {
                futureMonthDataArray[data.process_month] = {
                    count: futureMonthDataArray[data.process_month]?.count + 1,
                    monthData: [...futureMonthDataArray[data.process_month]?.monthData, data],
                };
            } else {
                futureMonthDataArray[data.process_month] = { count: 1, monthData: [data] };
            }
        }
        setFutureOrderData(futureMonthDataArray);
        setDataTableData((prev) => ({
            ...prev,
            data: futureMonthDataArray[dataTableData.month]?.monthData || [],
        }));

    }, [futureOrdersList, dataTableData.month]);

    const handleClick = () => {
        window.location.reload();
    };

    const handleRowsChange = (limit) => {
        if (limit === "All") {
            setLimit(totalRows);
            setRowLimit(totalRows);
        } else {
            setLimit(limit);
            setRowLimit(limit);
        }
    };

    const handleSearch = (filterText) => {
        if (filterText) {
            const filteredArray = dataTableData.data?.filter(
                (item) =>
                    (item?.order_id).toLowerCase().includes(filterText.toLowerCase()) ||
                    (item?.first_name).toLowerCase().includes(filterText.toLowerCase()) ||
                    (item?.email).toLowerCase().includes(filterText.toLowerCase()) ||
                    (item?.phone_number).toLowerCase().includes(filterText.toLowerCase())
            ).map((item) => item);

            setFutureOrdersData(filteredArray.length > 0 ? filteredArray : []);
        } else {
            setFutureOrdersData(dataTableData.data);
        }
    }

    const handleDeleteOrder = (e) => {
        e['selectedRows'] = e?.selectedRows?.map((item) => {
            return { ...item, id: item?.order_id, status: item?.process_status }
        })
        dispatch(handleChange(e))
    }

    return (
        <>
            <StyledContainer>
                <StyledPageTitle>
                    <h1>Future Order</h1>
                    <button type="button" className="btn btn-success" onClick={handleClick}>Refresh</button>

                </StyledPageTitle>
                {typeof dataTableData.renderDataTable !== "undefined" && dataTableData.renderDataTable && (
                    <ButtonWrapper>
                        <button
                            className="btn btn-success"
                            onClick={() => {
                                setDataTableData({
                                    renderDataTable: false,
                                    data: [],
                                    month: "",
                                });
                            }}
                        >
                            Back
                        </button>
                    </ButtonWrapper>
                )}
                {typeof dataTableData.renderDataTable != "undefined" && !dataTableData.renderDataTable ? (
                    <StylesCardList>
                        {months.map((month, i) => (
                            <StyledCard key={i}>
                                {typeof futureOrderData[month] !== "undefined" ? (
                                    <StyledCardContainer>
                                        <Link to="#" onClick={() => {
                                            setDataTableData({
                                                renderDataTable: true,
                                                data: futureOrderData[month]?.monthData || [],
                                                month: month,
                                            });
                                        }}>
                                            <h4>
                                                <span>
                                                    {month}{" "}
                                                    {typeof futureOrderData[month] === "undefined"
                                                        ? 0
                                                        : `(${futureOrderData?.[month].count})`}
                                                </span>
                                            </h4>
                                        </Link>
                                    </StyledCardContainer>
                                ) : (
                                    <StyledCardContainer>
                                        <Link to="#" onClick={() => {
                                            setDataTableData({
                                                renderDataTable: true,
                                                data: [],
                                                month: month,
                                            });
                                        }}>
                                            <h4>
                                                <span>
                                                    {month}{" "}
                                                    {typeof futureOrderData[month] === "undefined"
                                                        ? 0
                                                        : `(${futureOrderData?.[month].count})`}
                                                </span>
                                            </h4>
                                        </Link>
                                    </StyledCardContainer>
                                )}
                            </StyledCard>
                        ))}
                    </StylesCardList>
                ) : (
                    <StyledOrderBlock>
                        <div className="table-block table-responsive">
                            {/* <ReactDataTable
                                data={dataTableData.data}
                                setPage={setPage}
                                setLimit={setLimit}
                                customColumns={columns} 
                                orderName="futureOrder"
                            /> */}

                            <SearchField>
                                <DebounceInput
                                    type="search"
                                    id="search_field"
                                    className="search_field"
                                    onChange={(e) => handleSearch(e.target.value)}
                                    name="search_field"
                                    placeholder="Search"
                                />
                            </SearchField>
                            <StyledDataTable>
                                <DataTable
                                    className="uspassport-custom-tbl"
                                    columns={columns}
                                    data={futureOrdersData.length > 0 ? futureOrdersData : []}
                                    selectableRows={
                                        futureOrdersData?.length > 0 ? true : false
                                    }
                                    onSelectedRowsChange={(e) => handleDeleteOrder(e)}
                                    // onSelectedRowsChange={(e) => dispatch(handleChange(e))}
                                    progressPending={isLoading}
                                    fixedHeader={true}
                                    fixedHeaderScrollHeight="550px"
                                    pagination
                                    paginationPerPage={rowsPerPage}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    onChangePage={setCurrentPage}
                                    onChangeRowsPerPage={(newRowsPerPage) => {
                                        setRowsPerPage(newRowsPerPage);
                                        setCurrentPage(1);
                                    }}
                                    noDataComponent="No records found"
                                />
                            </StyledDataTable>
                            <ButtonWrapperDelete>
                                {userType === "Manager" || userType === "Admin" ? (
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <button
                                                type="button"
                                                name="submit"
                                                value="Delete"
                                                className="btn blue-btn"
                                                onClick={() => setShow(true)}
                                            >
                                                Delete Order{" "}
                                                <span>
                                                    <img src={rightArrow} alt="rightArrow" />
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                ) : null}
                            </ButtonWrapperDelete>
                        </div>
                        <MyModal
                            show={show}
                            close={handleClose}
                            size={"s"}
                            confirmAlert={true}
                            noEvent={() => handleClose()}
                            yesEvent={() => deleteOrder(selectedData)}
                        ></MyModal>
                    </StyledOrderBlock>
                )}
            </StyledContainer>
        </>
    );
};

export default FutureOrders;
